import { FC, FormEvent, useCallback, useEffect, useState } from "react";

import { cpf as cpfValidator } from "cpf-cnpj-validator";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ptBR } from "@mui/x-date-pickers/locales";
import "dayjs/locale/pt";

import ArrowForward from "@mui/icons-material/ArrowForward";

import { baseAPI, baseHeaders, studentAPI } from "src/shared/config/axios";

import CustomLoader from "src/shared/components/general/Loader";

import { usePerson } from "src/shared/contexts/Person";
import { useProduct } from "src/shared/contexts/Product";
import { useToast } from "src/shared/contexts/Toast";

import IAccordion from "src/shared/interfaces/accordions";

import CustomAccordion from "src/shared/components/general/Accordion";

import {
  InputItem,
  InputList,
} from "src/shared/styles/accordions/accordion.style";

const PersonInfoAccordion: FC<IAccordion> = ({
  id,
  disabled,
  expanded,
  accordions,
  setAccordions,
  setActiveStep,
}) => {
  /**Função de adição de um toast informativo */
  const { addToast } = useToast();
  /**Variável que mantém as informações do usuário */
  const { personInfo, setPersonInfo } = usePerson();
  /**Variável que mantém as informações dos produtos */
  const { acquiredProducts } = useProduct();

  /**Flag que controla se as operações de submissão do accordion estão ocorrendo */
  const [isLoading, setIsLoading] = useState(false);
  const [account, setAccount] = useState<{ email: string; name: string }>({
    email: "",
    name: "",
  });
  /** Variável que indica a primeira modificação de personInfo na página */
  const [firstIteration, setFirstIteration] = useState<boolean>(true);

  const regexEmail = /^.{2,}@.{5,}$/;
  const regexBirthday = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
  const regexPhone = /^\(\d{2}\)\d{5}-\d{4}$/;
  const regexCPF = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

  /**Função que realiza a máscara do input de CPF */
  const handleMaskCPF = useCallback(
    (value: string) => {
      value =
        value.length > 3
          ? value
              .replace(/\D/g, "")
              .replace(/(\d{3})(\d)/, "$1.$2")
              .replace(/(\d{3})(\d)/, "$1.$2")
              .replace(/(\d{3})(\d{1,2})$/, "$1-$2")
          : value;

      setPersonInfo((prev) => {
        return {
          ...prev,
          cpf: value,
        };
      });
    },
    [setPersonInfo]
  );
  /**Função que realiza a validação do input de CPF */
  const handleValidateCPF = useCallback(() => {
    return cpfValidator.isValid(
      personInfo["cpf"].replace(".", "").replace(".", "").replace("-", "")
    );
  }, [personInfo]);

  /**Função que realiza a máscara do input de telefone */
  const handleMaskPhone = useCallback(
    (value: string) => {
      value =
        value.length > 3
          ? value
              .replace(/\D/g, "")
              .replace(/^(\d{2})/, "($1)")
              .replace(/(\d)(\d{4})$/, "$1-$2")
          : value;

      setPersonInfo((prev) => {
        return {
          ...prev,
          phone: value,
        };
      });
    },
    [setPersonInfo]
  );

  useEffect(() => {
    // Função que configura o script do Active Campaign
    (function (e: any, t: any, o: any, n: any) {
      e.visitorGlobalObjectAlias = n;
      e[e.visitorGlobalObjectAlias] =
        e[e.visitorGlobalObjectAlias] ||
        function () {
          (e[e.visitorGlobalObjectAlias].q =
            e[e.visitorGlobalObjectAlias].q || []).push(arguments);
        };
      // e[e.visitorGlobalObjectAlias].l = (new Date).getTime();
      e[e.visitorGlobalObjectAlias].l = new Date().getTime();
      const r = t.createElement("script");
      r.src = o;
      r.async = true;
      const i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
    })(
      window,
      document,
      "https://diffuser-cdn.app-us1.com/diffuser/diffuser.js",
      "vgo"
    );

    // Certifique-se de que vgo está disponível antes de chamar
    const checkVgo = () => {
      if ((window as any).window.vgo) {
        (window as any).window.vgo("setAccount", "651291305");
        (window as any).window.vgo("setTrackByDefault", true);
        (window as any).window.vgo("process");
      } else {
        setTimeout(checkVgo, 100);
      }
    };
    checkVgo();
  }, []);

  /** Realiza validações necessárias nas infos de dados pessoais na primeira renderização*/
  useEffect(() => {
    if (!firstIteration) return;

    setFirstIteration(false);

    const regexPhone = /^\(\d{2}\)\d{5}-\d{4}$/;
    const regexCPF = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

    if (!regexPhone.test(personInfo.phone)) {
      personInfo.phone =
        personInfo.phone.length > 3
          ? personInfo.phone
              .replace(/\D/g, "")
              .replace(/^(\d{2})/, "($1)")
              .replace(/(\d)(\d{4})$/, "$1-$2")
          : personInfo.phone;

      setPersonInfo((prev) => {
        return {
          ...prev,
          phone: personInfo.phone,
        };
      });
    }

    if (!regexCPF.test(personInfo.cpf)) {
      personInfo.cpf =
        personInfo.cpf.length > 3
          ? personInfo.cpf
              .replace(/\D/g, "")
              .replace(/(\d{3})(\d)/, "$1.$2")
              .replace(/(\d{3})(\d)/, "$1.$2")
              .replace(/(\d{3})(\d{1,2})$/, "$1-$2")
          : personInfo.cpf;

      setPersonInfo((prev) => {
        return {
          ...prev,
          cpf: personInfo.cpf,
        };
      });
    }
  }, [personInfo, setPersonInfo, firstIteration, setFirstIteration]);
  /**Função responsável por validar todos os campos e submeter o accordion */
  const handleSubmitAccordion = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      setIsLoading(true);

      /**Verifica a presença de inputs inválidos, ou de um cpf incorreto */
      if (
        document.querySelectorAll(".personalInfoInput.invalid").length === 0
      ) {
        if ((window as any).vgo) {
          (window as any).vgo("setEmail", personInfo.email);
          (window as any).vgo("process");
        }

        /**Caso o accordion de informações de endereço já tenha sido concluído */
        if (
          accordions!.filter(
            ({ id, completed }) => id === 2 && completed === true
          )[0]
        ) {
          try {
            /**Verifica a existência de uma pessoa cadastrada com o cpf informado */
            const {
              data: { response },
            } = await baseAPI.get(`/persons/by_cpf/?cpf=${personInfo["cpf"]}`, {
              headers: baseHeaders(),
            });

            let person_id = "";
            let b2bCompany = "";

            /**Caso a pessoa não exista, cadastra a mesma */
            if (!response) {
              const { ...inputs } = personInfo;

              /**Cadastra a pessoa no banco, e recupera o id criado */
              const {
                data: {
                  response: { crypted_id, b2b_company_name },
                },
              } = await baseAPI.post(
                `/persons/`,
                {
                  ...inputs,
                  number: Number(inputs["number"]),
                },
                { headers: baseHeaders() }
              );

              person_id = crypted_id;
              b2bCompany = b2b_company_name;
            } else {
              person_id = response.crypted_id;
              b2bCompany = response.b2b_company_name;
            }

            if (b2bCompany) {
              addToast({
                title: "Parceiro B2B - " + b2bCompany,
                description:
                  "Bem-vindo(a) à Faculdade XP, colaborador(a) " +
                  b2bCompany +
                  "!",
                type: "success",
              });
            }

            /**Atualiza as informações do usuário no Principia (empresa responsável pela funcionalidade de boleto parcelado) */
            const {
              birthDate,
              cep,
              state,
              city,
              neighborhood,
              street,
              number,
              complement,
            } = personInfo;
            await baseAPI.put(
              `/persons/${person_id}/boleto_parcelado/`,
              {
                birthday: birthDate,
              },
              {
                headers: baseHeaders(),
              }
            );
            await baseAPI.post(
              `/persons/${person_id}/address/`,
              {
                cep,
                state,
                city,
                neighborhood,
                street,
                number,
                complement,
              },
              {
                headers: baseHeaders(),
              }
            );

            /**Variavel para requisição de criação de lead */
            const leadCreationObject: {
              person_id: string;
              mql_email?: string;
              product_name?: string;
              utm_source?: string | null;
              utm_medium?: string | null;
              utm_campaign?: string | null;
              utm_content?: string | null;
              utm_term?: string | null;
              affiliate_id?: string;
              banner_id?: string;
            } = {
              person_id,
              mql_email: personInfo.email,
              utm_source: personInfo.utmSource,
              utm_medium: personInfo.utmMedium,
              utm_campaign: personInfo.utmCampaign,
              utm_content: personInfo.utmContent,
              utm_term: personInfo.utmContent,
            };

            if (acquiredProducts && acquiredProducts.length > 0) {
              leadCreationObject["product_name"] =
                acquiredProducts[0].nome_exibicao || acquiredProducts[0].nome;
            }

            // Obter cookie
            const cookiesArray = document.cookie.split(";");

            for (let i = 0; i < cookiesArray.length; i++) {
              let cookie = cookiesArray[i].trim();

              if (cookie.indexOf("mgm_pap=") === 0) {
                const mgmCookie = cookie.substring("mgm_pap=".length);

                if (mgmCookie) {
                  const cookieValues = mgmCookie.split("&");
                  let affiliateId = null;
                  let bannerId = null;

                  cookieValues.forEach((pair) => {
                    const [key, value] = pair.split("=");
                    if (key === "a_aid") affiliateId = value;
                    if (key === "a_bid") bannerId = value;
                  });

                  if (affiliateId)
                    leadCreationObject.affiliate_id = affiliateId;
                  if (bannerId) leadCreationObject.banner_id = bannerId;
                }
              }
            }

            /**Gera o lead a ser utilizado pelo usuário */
            const {
              data: {
                response: { crypted_id: lead },
              },
            } = await baseAPI.post(`/leads/`, leadCreationObject, {
              headers: baseHeaders(),
            });

            /**Caso o usuário esteja atualizando suas informações pessoais após confirmar o produto, é necessário atualizar o lead */
            if (acquiredProducts && acquiredProducts.length > 0) {
              await baseAPI.put(
                `/leads/${lead}/`,
                {
                  product_list: acquiredProducts.map(
                    ({ crypted_id }) => crypted_id
                  ),
                  product_name: acquiredProducts[0].nome,
                },
                { headers: baseHeaders() }
              );
            }

            /**Grava o lead em sessionStorage */
            sessionStorage.setItem("@checkout_lead@", lead);

            /**Insere as informações do usuário no respectivo contexto */
            setPersonInfo((prev) => {
              return { ...prev, id: person_id, b2bCompany };
            });

            /**Marca a flag, indicando que o accordion foi corretamente validado */
            setAccordions((prev) =>
              prev.map((acc) =>
                acc.id === id
                  ? {
                      ...acc,
                      group: 1,
                      completed: true,
                      disabled: true,
                      completedInfo: {
                        ...acc.completedInfo,
                        content: [personInfo.name, personInfo.email],
                      },
                    }
                  : prev.find(
                      ({ id: prev_id, disabled }) => prev_id !== id && !disabled
                    )
                  ? { ...acc }
                  : acc.id === id + 1
                  ? { ...acc, disabled: false, expanded: true }
                  : { ...acc }
              )
            );

            addToast({
              type: "success",
              title: "Informações confirmadas",
              description:
                "As informações pessoais foram validadas e registradas com sucesso",
            });
          } catch (e) {
            console.log(e);

            addToast({
              type: "error",
              title: "Erro ao confirmar informações",
              description:
                "Ocorreu um erro ao processar a solicitação. Recarregue a página e tente novamente.",
            });
          }
        } else {
          const account = await studentAPI
            .get(`/account/?cpf=${personInfo.cpf}`)
            .then((res) => {
              if ("error" in res.data) {
                return null;
              }

              return res.data
            });

          if (account) {
            addToast({
              type: "success",
              title: `Olá, ${account.name}`,
              description: `Seu CPF está vinculado ao email ${account.email}. Após o pagamento, seu produto estará disponível em sua conta.`,
            });

            setPersonInfo((prev) => {
              return { ...prev, email: account.email, name: account.name };
            });

            setAccount({ email: account.email, name: account.name });
          } else {
            addToast({
              type: "success",
              title: "Informações confirmadas",
              description:
                "Suas informações pessoais foram validadas e registradas com sucesso",
            });
          }
          /**Marca a flag, indicando que o accordion foi corretamente validado */
          setAccordions((prev) =>
            prev.map((acc) =>
              acc.id === id
                ? {
                    ...acc,
                    group: 1,
                    completed: true,
                    disabled: true,
                    completedInfo: {
                      ...acc.completedInfo,
                      content: !account
                        ? [personInfo.name, personInfo.email]
                        : [account.name, account.email],
                    },
                  }
                : prev.find(
                    ({ id: prev_id, disabled }) => prev_id !== id && !disabled
                  )
                ? { ...acc }
                : acc.id === id + 1
                ? { ...acc, disabled: false, expanded: true }
                : { ...acc }
            )
          );
        }
      } else {
        setAccordions((prev) =>
          prev.map((acc) =>
            acc.id === id ? { ...acc, completed: false } : { ...acc }
          )
        );

        if (!handleValidateCPF()) {
          addToast({
            type: "info",
            title: "CPF inválido",
            description: "O CPF informado está incorreto",
          });
        } else {
          addToast({
            type: "info",
            title: "Informações faltantes",
            description: "Preencha os campos corretamente",
          });
        }
      }

      setIsLoading(false);
    },
    [
      id,
      acquiredProducts,
      accordions,
      setAccordions,
      handleValidateCPF,
      personInfo,
      setPersonInfo,
      addToast,
    ]
  );

  /**Efeito que atualiza a variável que mantém o passo atual do usuário, quando o accordion for validado */
  useEffect(() => {
    setActiveStep(accordions!.findIndex(({ disabled }) => !disabled));
  }, [accordions, setActiveStep]);

  return (
    <CustomAccordion
      id={id}
      title="Dados Pessoais"
      disabled={disabled}
      expanded={expanded}
      setAccordions={setAccordions}
    >
      <form onSubmit={(e) => handleSubmitAccordion(e)}>
        <InputList>
          <InputItem sizes={[1]}>
            <TextField
              id="username"
              className={`${
                personInfo.name.length < 10 ? "invalid" : ""
              } personalInfoInput`}
              label="Nome Completo"
              variant="outlined"
              inputProps={{ minLength: 10, maxLength: 75 }}
              value={personInfo["name"] || ""}
              required
              onChange={({ target: { value } }) =>
                setPersonInfo((prev) => {
                  return { ...prev, name: value };
                })
              }
            />
          </InputItem>

          <InputItem sizes={[1]}>
            <TextField
              id="cpf"
              className={`${
                personInfo.cpf.length < 11 || !handleValidateCPF()
                  ? "invalid"
                  : ""
              } personalInfoInput`}
              label="CPF"
              variant="outlined"
              inputProps={{ minLength: 14, maxLength: 14 }}
              value={personInfo["cpf"] || ""}
              error={personInfo.cpf.length === 14 && !handleValidateCPF()}
              helperText={
                personInfo.cpf.length === 14 &&
                !handleValidateCPF() &&
                "CPF inválido"
              }
              required
              onChange={({ target: { value } }) => handleMaskCPF(value)}
            />
          </InputItem>

          <InputItem sizes={[1]}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt"
              localeText={
                ptBR.components.MuiLocalizationProvider.defaultProps.localeText
              }
            >
              <DatePicker
                className={`${
                  personInfo.birthDate && personInfo.birthDate.length < 1
                    ? "invalid"
                    : ""
                } personalInfoInput`}
                label="Data de Nascimento"
                format="DD/MM/YYYY"
                value={
                  personInfo.birthDate
                    ? new AdapterDayjs().dayjs(
                        personInfo.birthDate,
                        "DD/MM/YYYY"
                      )
                    : null
                }
                onChange={(val) => {
                  if (val) {
                    //@ts-ignore
                    const { $D, $M, $y } = val;

                    setPersonInfo((prev) => {
                      return {
                        ...prev,
                        birthDate: `${$D.toString().padStart(2, "0")}/${($M + 1)
                          .toString()
                          .padStart(2, "0")}/${$y}`,
                      };
                    });
                  } else
                    setPersonInfo((prev) => {
                      return {
                        ...prev,
                        birthDate: "",
                      };
                    });
                }}
              />
            </LocalizationProvider>
          </InputItem>

          <InputItem sizes={[1]}>
            <TextField
              id="email"
              className={`${
                personInfo.email.length < 5 ? "invalid" : ""
              } personalInfoInput`}
              type="email"
              label="Email"
              variant="outlined"
              inputProps={{ minLength: 5, maxLength: 75 }}
              value={personInfo["email"] || ""}
              error={
                personInfo.email.length > 3 &&
                !regexEmail.test(personInfo.email)
              }
              helperText={
                personInfo.email.length > 3 &&
                !regexEmail.test(personInfo.email) &&
                "Email inválido"
              }
              required
              onChange={({ target: { value } }) =>
                setPersonInfo((prev) => {
                  return { ...prev, email: value };
                })
              }
            />
          </InputItem>

          <InputItem sizes={[1]}>
            <TextField
              id="phone"
              className={`${
                personInfo.phone.length < 14 ? "invalid" : ""
              } personalInfoInput`}
              label="Celular (com DDD)"
              variant="outlined"
              inputProps={{ minLength: 14, maxLength: 14 }}
              value={personInfo["phone"] || ""}
              required
              onChange={({ target: { value } }) => handleMaskPhone(value)}
            />
          </InputItem>
        </InputList>

        <Button
          id="personalInfoSubmitButton"
          type="submit"
          variant="contained"
          color="success"
          endIcon={<ArrowForward />}
          disabled={
            !regexPhone.test(personInfo.phone) ||
            !regexCPF.test(personInfo.cpf) ||
            personInfo.name.length < 10 ||
            !regexBirthday.test(personInfo.birthDate) ||
            !regexEmail.test(personInfo.email)
          }
          sx={{ width: "100%" }}
        >
          {isLoading ? <CustomLoader /> : <span>Continuar</span>}
        </Button>
      </form>
    </CustomAccordion>
  );
};

export default PersonInfoAccordion;
